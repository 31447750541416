import React, { useState, useEffect } from "react";
import Navigation from "./Navigation";
import {
  Container,
  Divider,
  Grid,
  Image,
  Modal,
  Header,
} from "semantic-ui-react";
import RunningCard from "./RunningCard";
import "./MobileApps.css";

const mobileApps = [
  {
    title: "DNA TV",
    src: "/images/pro/mobile/dna_1.jpg",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "DNA TV",
    src: "/images/pro/mobile/dna_2.jpg",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "DNA TV",
    src: "/images/pro/mobile/dna_3.jpg",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "DNA TV",
    src: "/images/pro/mobile/dna_4.jpg",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "DNA TV",
    src: "/images/pro/mobile/dna_5.jpg",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "DNA TV",
    src: "/images/pro/mobile/dna_6.jpg",
    device: "iPhoneX",
    deviceColor: "black",
  },
];

const mobileImages = [
  {
    title: "Demo day at DNA TV",
    description: "Weekly demo presentation of Sprint progress",
    src: "/images/pro/mobile/dna_demo.jpg",
    width: 11,
  },
  {
    title: "Sanoma Tech Talks",
    description: '"Native vs. Hybrid Mobile Developement"',
    src: "/images/pro/mobile/tt_sanoma.jpg",
    width: 5,
  },
];

const ProMobileApps = () => {
  const [open, setOpen] = useState(false);
  const [modalCaption, setModalCaption] = useState("");
  const [modalImage, setModalImage] = useState("");

  const handleOpen = (caption, image) => {
    setModalCaption(caption);
    setModalImage(image);
    setOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const apps = mobileApps.map((app) => {
    const screenProp = <Image src={app.src} />;
    return (
      <Grid.Column key={app.src}>
        <div className="device-wrapper">
          <div
            className="device"
            data-device={app.device}
            data-orientation="portrait"
            data-color={app.deviceColor ? app.deviceColor : "white"}
          >
            <div className="screen">{screenProp}</div>
          </div>
        </div>
      </Grid.Column>
    );
  });

  const images = mobileImages.map((image) => {
    return (
      <RunningCard key={image.image} image={image} handleOpen={handleOpen} />
    );
  });

  return (
    <div>
      <Navigation page="Professional Mobile Apps" />
      <header>
        <Container>
          <h1>Professional Mobile Apps</h1>
          <p>
            For the past three years I have worked as a lead/principal iOS
            developer of DNA TV - a streaming app capable of streaming live TV,
            recordings and VODs. This was a greenfield project that I have had
            the honor of architecting and coding from the very beginning. The
            app is done using Swift/RxSwift and uses MVVM architecture. Every UI
            component is build from scratch.
          </p>
          <p>
            DNA TV was a finalist for{" "}
            <a href="https://grandone.fi/kilpailutyo/?entry=dna-tv">
              Grand One best User Experience category
            </a>
            .
          </p>
          <p>
            In addition to DNA TV, I've also worked on another streaming app, a
            social media app, a security app and a POC where a phone could be
            used as a key to unlock a hotel door via Bluetooth communication.
          </p>
        </Container>
      </header>
      <Divider hidden />
      <Container>
        <Divider horizontal>DNA TV</Divider>
        <Grid stackable centered columns={3}>
          {apps}
        </Grid>
        <Divider hidden />
        <Divider section />
        <Divider hidden />
        <Grid stackable centered columns={2}>
          {images}
        </Grid>
      </Container>
      <Divider hidden />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Header>{modalCaption}</Header>
        <Modal.Content>
          <Image src={modalImage} style={{ margin: "0 auto" }} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ProMobileApps;
