import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "./Main";
import Running from "./Running";
import MobileApps from "./MobileApps";
import MobileApp from "./MobileApp";
import ProMobileApps from "./ProMobileApps";
import ProWebApps from "./ProWebApps";
import Privacy from "./Privacy";
import ContactForm from "./ContactForm";
import Helmet from "react-helmet";

const App = () => {
  return (
    <div>
      <Helmet>
        <title>Filipp Zimin - Developer</title>
      </Helmet>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/running" exact component={Running} />
          <Route path="/mobile" exact component={MobileApps} />
          <Route path="/mobile/privacy" exact component={Privacy} />
          <Route path="/mobile/:name" exact component={MobileApp} />
          <Route path="/pro/mobile" exact component={ProMobileApps} />
          <Route path="/pro/web" exact component={ProWebApps} />
        </Switch>
      </BrowserRouter>
      <ContactForm />
    </div>
  );
};

export default App;
