import React from "react";
import { Link } from "react-router-dom";
//import "./Navigation.css";
import { Container, Breadcrumb } from "semantic-ui-react";

const Navigation = ({ page }) => {
  return (
    <Container style={{ padding: "1em 0" }}>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Link to="/">Main Page</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{page}</Breadcrumb.Section>
      </Breadcrumb>
    </Container>
  );
};

export default Navigation;
