import React, { useState, useEffect } from "react";
import "./Running.css";
import RunningCard from "./RunningCard";
import Navigation from "./Navigation";
import {
  Container,
  Divider,
  Grid,
  Card,
  Icon,
  Modal,
  Header,
  Image,
} from "semantic-ui-react";

const runningImages = [
  {
    title: "Helsinki City Run",
    description: "3.10.2020",
    src: "/images/running/hcr_2020.jpg",
  },
  {
    title: "Helsinki Half Marathon",
    description: "26.09.2020, 1:40 pacer duty",
    src: "/images/running/hhm_2020.jpg",
    columnClass: "image-card-wide",
  },
  {
    title: "BMW Berlin Marathon",
    description: "29.09.2019",
    src: "/images/running/berlin_2019a.jpg",
    columnClass: "image-card-wide",
  },
  {
    title: "BMW Berlin Marathon",
    description: "29.09.2019",
    src: "/images/running/berlin_2019.jpg",
  },
  {
    title: "Asics Stockholm Marathon",
    description: "1.06.2019",
    src: "/images/running/stockholm_2019.jpg",
  },
  {
    title: "Helsinki City",
    description: "19.05.2018",
    src: "/images/running/hcr2018.jpg",
  },
  {
    title: "Länsiväyläjuoksu",
    description: "28.04.2018",
    src: "/images/running/lvj2018.jpg",
  },
  {
    title: "Toughest Oslo",
    description: "02.09.2017",
    src: "/images/running/to2017.jpg",
  },
  {
    title: "Helsinki Trail Run 2017",
    description: "01.07.2017",
    content: "Won this race",
    columnClass: "image-card-wide",
    src: "/images/running/htr2017.jpg",
  },
  {
    title: "Helsinki City Marathon",
    description: "12.08.2017",
    src: "/images/running/hcm2017.jpg",
  },
  {
    title: "Helsinki City Run 2017",
    description: "13.05.2017",
    src: "/images/running/hcr2017.jpg",
  },
  {
    title: "Finlandia Hiihto",
    description: "05.02.2017",
    src: "/images/running/finlandia_hiihto_2017.jpg",
  },
  {
    title: "Espoo Rantamaraton",
    description: "19.05.2018",
    src: "/images/running/rantamaraton_2016.jpg",
  },
  {
    title: "Helsinki City Marathon",
    description: "13.08.2016",
    src: "/images/running/hcm_2016.jpg",
  },
  {
    title: "Helsinki City Marathon",
    description: "13.08.2016",
    src: "/images/running/hcm_2016_img_1.jpg",
  },
  {
    title: "Helsinki City Run",
    description: "16.05.2015",
    src: "/images/running/hcr_2015.jpg",
  },
  {
    title: "Hercules Trail Run",
    description: "10.07.2016",
    src: "/images/running/htr_2016.jpg",
    columnClass: "image-card-wide",
  },
];

const records = [
  {
    type: "Marathon",
    event: "Asics Stockholm Marathon",
    date: "1.06.2019",
    time: "2:59:17",
  },
  {
    type: "Half-Marathon",
    event: "Helsinki City Run",
    date: "3.10.2020",
    time: "1:24:17",
  },
  {
    type: "10k",
    event: "Helsinki Midnight Run",
    date: "31.08.2019",
    time: "39:17",
  },
  {
    type: "7k",
    event: "Espoo Iltajuoksu",
    date: "4.05.2018",
    time: "26:48",
  },
];

const Running = () => {
  const [open, setOpen] = useState(false);
  const [modalCaption, setModalCaption] = useState("");
  const [modalImage, setModalImage] = useState("");

  const handleOpen = (caption, image) => {
    setModalCaption(caption);
    setModalImage(image);
    setOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = runningImages.map((image) => {
    return (
      <RunningCard key={image.image} image={image} handleOpen={handleOpen} />
    );
  });

  const renderRecords = records.map((record) => {
    return (
      <div className="record-card" key={record.type}>
        <div>
          <Header as="h3">{record.type}</Header>
          <div className="meta">
            <span className="date">{record.date}</span>
          </div>
          <p>{record.event}</p>
        </div>
        <div className="record-footer">
          <Icon name="stopwatch" />
          {record.time}
        </div>
      </div>
    );
  });

  return (
    <div>
      <Navigation page="Running" />
      <header>
        <Container>
          <h1>Running</h1>
          <p>
            I started running around 15 years ago. In 2015, after completing my
            first half-marathon, running became a serious hobby. Running has
            transformed me as an individual and helped me develop confidence,
            great work ethic and discipline - skills that have benefited me
            greatly.
          </p>
          <p>
            I have participated in around 70 races, including road races, trail
            races and obstacle course races. I also volunteer as a pacer/guide
            when possible. My favorite distance is the marathon. My go to
            running shoes are Altra.
          </p>
        </Container>
      </header>
      <Divider hidden />
      <Container>
        <h2>Personal records</h2>
        <div className="row">{renderRecords}</div>
        <Divider section />
        <div className="row">{images}</div>
        <Divider hidden />
      </Container>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Header>{modalCaption}</Header>
        <Modal.Content>
          <Image src={modalImage} style={{ margin: "0 auto" }} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default Running;
