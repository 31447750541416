import React, { useState, useEffect } from "react";
import "./Running.css";
import RunningCard from "./RunningCard";
import Navigation from "./Navigation";
import {
  Container,
  Divider,
  Grid,
  Modal,
  Header,
  Image,
} from "semantic-ui-react";

const webMobileImages = [
  {
    title: "Arton Auto",
    description: "Mobile view",
    src: "/images/pro/web/artonauto_mobile.jpg",
  },
  {
    title: "Vaihtoareena",
    description: "Mobile view",
    src: "/images/pro/web/vaihtoareena_mobile.jpg",
  },
  {
    title: "Vaunula",
    description: "Mobile view",
    src: "/images/pro/web/vaunula_mobile.jpg",
  },
];
const webImages = [
  {
    title: "Stock Management",
    description: "Search, view, manage vehicles",
    src: "/images/pro/web/varastonhallinta_mobiili.jpg",
    columnClass: "image-card-wide",
  },
  {
    title: "PDF Pricetag",
    description: "Server-side generated PDF",
    src: "/images/pro/web/pricetag.jpg",
  },
  {
    title: "BMW Suomi CRM",
    description: "Client relationship management",
    src: "/images/pro/web/bmw_crm.png",
    columnClass: "image-card-medium",
  },
  {
    title: "BMW Suomi Aftersales",
    description: "App to monitor aftersales activity",
    src: "/images/pro/web/bmw_aftersales.png",
    columnClass: "image-card-medium",
  },
];

const ProWebApps = () => {
  const [open, setOpen] = useState(false);
  const [modalCaption, setModalCaption] = useState("");
  const [modalImage, setModalImage] = useState("");

  const handleOpen = (caption, image) => {
    setModalCaption(caption);
    setModalImage(image);
    setOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = webImages.map((image) => {
    return (
      <RunningCard key={image.image} image={image} handleOpen={handleOpen} />
    );
  });

  const mobileImages = webMobileImages.map((image) => {
    return (
      <RunningCard key={image.image} image={image} handleOpen={handleOpen} />
    );
  });

  return (
    <div>
      <Navigation page="Professional Web Applications" />
      <header>
        <Container>
          <h1>Professional Web Applications</h1>
          <p>
            I first started making web sites and web apps professionally in
            around 2006, even worked on some Flash/ActionScript applications
            around 10 years ago. All in all, I have worked on around 30
            websites, including Länsi-Auto, Rinta Jouppi, Vaunula, Jeep,
            Alfa-Romeo, and over a dozen web apps, such as a reservation
            application and calculating sales provision.
          </p>
          <p>
            I'm currently working as a fullstack developer for Finnair
            (In-Flight Entertainment Connectivity). The stack consists of Node,
            React, AWS, Serverless, Cypress, Jenkins, MongoDB, QML.
          </p>
          <p>Below is just a small batch of some of my work.</p>
        </Container>
      </header>
      <Divider hidden />
      <Container>
        <Divider horizontal>Samples</Divider>
        <Grid stackable centered columns={3}>
          {images}
        </Grid>
        <Divider hidden />
        <Divider horizontal>Websites</Divider>
        <Grid stackable centered columns={3}>
          {mobileImages}
        </Grid>
        <Divider hidden />
      </Container>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Header>{modalCaption}</Header>
        <Modal.Content>
          <Image src={modalImage} style={{ margin: "0 auto" }} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ProWebApps;
