export const apps = {
  percentical: {
    title: "PercentiCal - percentage calculator. Add & deduct",
    headerMain: "PercentiCal",
    headerSecondary: "an iOS app",
    badgeId: "1252263818",
    storeLink:
      "https://itunes.apple.com/us/app/percentical-percentage-calculator-add-deduct/id1252263818",
    heading: "PercentiCal",
    subtitle: "Percentage calculator. Add & deduct. Calculate change.",
    screens: [
      {
        caption: "PercentiCal is a powerful percentage calculator",
        screen: "/images/mobile/Showcase/percentical/images/screen0.png",
      },
      {
        caption: "Add percentages",
        screen: "/images/mobile/Showcase/percentical/images/screen1.png",
      },
      {
        caption: "Deduct percentages",
        screen: "/images/mobile/Showcase/percentical/images/screen2.png",
      },
      {
        caption: "Bright, modern & clean interface",
        screen: "/images/mobile/Showcase/percentical/images/screen3.png",
      },
      {
        caption: "Email your calculation results",
        screen: "/images/mobile/Showcase/percentical/images/screen4.png",
      },
    ],
  },
  clearthinker: {
    title: "Clear Thinker - Sobriety Counter iOS App",
    headerMain: "Clear Thinker",
    headerSecondary: "an iOS app",
    badgeId: "1018633653",
    storeLink: "https://itunes.apple.com/us/app/clear-thinker/id1018633653",
    heading: "Clear Thinker",
    subtitle: "Sobriety Counter",
    screens: [
      {
        caption: "Keep track of your sobriety and targets",
        screen: "/images/mobile/Showcase/clearthinker/images/main_screen1.png",
      },
      {
        caption: "Settings let you edit dates and share your progress",
        screen: "/images/mobile/Showcase/clearthinker/images/menu.png",
      },
      {
        caption: "Stay motivated with quotes from great people",
        screen: "/images/mobile/Showcase/clearthinker/images/quote.png",
      },
      {
        caption: "Unlock milestone achievements",
        screen: "/images/mobile/Showcase/clearthinker/images/achievements.png",
      },
      {
        caption: "Visualize your journey with a calendar",
        screen: "/images/mobile/Showcase/clearthinker/images/calendar.png",
      },
      {
        caption: "View and edit your old sobriety entries",
        screen: "/images/mobile/Showcase/clearthinker/images/ct4.png",
      },
      {
        caption: "Read thoughts on sobriety",
        screen: "/images/mobile/Showcase/clearthinker/images/writing.png",
      },
    ],
  },
  pacesplits: {
    title: "Pace & Splits - Running Race Calculator",
    headerMain: "Pace & Splits",
    headerSecondary: "an iOS app",
    badgeId: "1253074063",
    storeLink:
      "https://itunes.apple.com/us/app/pace-splits-running-race-calculator/id1253074063",
    heading: "Pace & Splits",
    subtitle: "Running Race Calculator",
    screens: [
      {
        caption: "Pace & Splits is a calculator for runners made by a runner",
        screen: "/images/mobile/Showcase/pacesplits/images/ps3.png",
      },
      {
        caption: "Supports both imperical and metric units",
        screen: "/images/mobile/Showcase/pacesplits/images/ps1.png",
      },
      {
        caption: "Supports both imperical and metric units",
        screen: "/images/mobile/Showcase/pacesplits/images/ps2.png",
      },
      {
        caption: "Shows lap times for track races",
        screen: "/images/mobile/Showcase/pacesplits/images/ps5.png",
      },
      {
        caption: "Includes average pace and splits for many world records",
        screen: "/images/mobile/Showcase/pacesplits/images/ps4.png",
      },
      {
        caption: "Simple, clean and easy-to-use",
        screen: "/images/mobile/Showcase/pacesplits/images/ps7.png",
      },
    ],
  },
  plantsbeans: {
    title: "Plants & Beans - 100% Plant-Based Recipes",
    headerMain: "Plants & Beans",
    headerSecondary: "an iOS app",
    badgeId: "1257642048",
    storeLink:
      "https://itunes.apple.com/us/app/pace-splits-running-race-calculator/id1253074063",
    heading: "Plants & Beans",
    subtitle: "100% Plant-Based Recipes",
    screens: [
      {
        caption: "Plant-Based Meals, Healthy & Delicious",
        screen: "/images/mobile/Showcase/plantsbeans/images/pb2.jpg",
      },
      {
        caption: "Simple & easy to use, comes with a timer",
        screen: "/images/mobile/Showcase/plantsbeans/images/pb4.jpg",
      },
      {
        caption: "Tastes of the World, a careful selection of dishes",
        screen: "/images/mobile/Showcase/plantsbeans/images/pb3.jpg",
      },
      {
        caption: "Handy Information & tips",
        screen: "/images/mobile/Showcase/plantsbeans/images/pb5.jpg",
      },
    ],
  },
  runable: {
    title: "Runable - GPS Tracking & Run Log",
    headerMain: "Runable",
    headerSecondary: "an iOS app",
    badgeId: "1272578082",
    storeLink: "https://itunes.apple.com/us/app/runable/id1272578082",
    heading: "Runable",
    subtitle: "GPS Tracking & Run Log",
    screens: [
      {
        caption: "Runable is a fantastic tool for runners",
        screen: "/images/mobile/Showcase/trackrun/images/tr1.png",
      },
      {
        caption: "Access to all your vital stats right at yor fingertips",
        screen: "/images/mobile/Showcase/trackrun/images/tr2.png",
      },
      {
        caption: "Voice-guided, customisable GPS tracking",
        screen: "/images/mobile/Showcase/trackrun/images/tr3.png",
      },
      {
        caption: "Ability to add and track any past runs you have",
        screen: "/images/mobile/Showcase/trackrun/images/tr4.png",
      },
      {
        caption:
          "Take your running to the next level with the help of workouts...",
        screen: "/images/mobile/Showcase/trackrun/images/tr6.png",
      },
      {
        caption: "...which can then be reviewd in detail",
        screen: "/images/mobile/Showcase/trackrun/images/tr7.png",
      },
      {
        caption: "Extensive customisation through In-App Purchases",
        screen: "/images/mobile/Showcase/trackrun/images/tr5.png",
      },
    ],
  },
  veganbeing: {
    title: "Vegan Being - Vegan & Vegetarian Counter iOS App",
    headerMain: "Vegan Being",
    headerSecondary: "an iOS app",
    badgeId: "1018633653",
    storeLink: "https://itunes.apple.com/us/app/vegan-being/id1182860751",
    heading: "Vegan Being",
    subtitle: "Counter, information, inspiration, motivation",
    screens: [
      {
        caption: "Track your days as a vegan and/or vegetarian",
        screen: "/images/mobile/Showcase/veganbeing/images/vb1.png",
      },
      {
        caption: "Stay motivated and inspired with cleaver quotes",
        screen: "/images/mobile/Showcase/veganbeing/images/vb2.png",
      },
      {
        caption: "Simple, light, beautiful and intuitive design",
        screen: "/images/mobile/Showcase/veganbeing/images/vb3.png",
      },
      {
        caption: "Learn about veganism and vegetarianism",
        screen: "/images/mobile/Showcase/veganbeing/images/vb4.png",
      },
      {
        caption: "Supports two counters. Keep track of ended periods",
        screen: "/images/mobile/Showcase/veganbeing/images/vb5.png",
      },
      {
        caption: "Powerful, yet easy to use",
        screen: "/images/mobile/Showcase/veganbeing/images/vb6.png",
      },
    ],
  },
  topeverything: {
    title: "Top Everything",
    headerMain: "Top Everything",
    headerSecondary: "an iOS app",
    badgeId: "1009831120",
    storeLink: "https://itunes.apple.com/us/app/top-everything/id1009831120",
    heading: "Top Everything",
    subtitle: "Top movies, music, books",
    screens: [
      {
        caption: "Choose from a variety of categories",
        screen: "/images/mobile/Showcase/topeverything/images/sc1.png",
      },
      {
        caption: "Sort by genre",
        screen: "/images/mobile/Showcase/topeverything/images/sc2.png",
      },
      {
        caption: "View details",
        screen: "/images/mobile/Showcase/topeverything/images/sc3.png",
      },
      {
        caption: "Preview music",
        screen: "/images/mobile/Showcase/topeverything/images/sc4.png",
      },
      {
        caption: "Simple and easy to use",
        screen: "/images/mobile/Showcase/topeverything/images/sc5.png",
      },
    ],
  },
  mindfulshopper: {
    title: "Mindful Shopper 3-in-1: Lists, Receipts, Calculators iOS App",
    headerMain: "Mindful Shopper",
    headerSecondary: "an iOS app",
    badgeId: "1033946525",
    storeLink: "https://itunes.apple.com/us/app/pro-shopper/id1033946525",
    heading: "Mindful Shopper",
    subtitle: "Lists, Receipts, Calculators",
    screens: [
      {
        caption: "Pro Shopper is your 3-in-1 Shopping Assistant",
        screen: "/images/mobile/Showcase/mindfulshopper/images/sc1.png",
      },
      {
        caption: "Keep track of the cost of your shopping basket",
        screen: "/images/mobile/Showcase/mindfulshopper/images/sc3.png",
      },
      {
        caption: "Add and customize shopping lists",
        screen: "/images/mobile/Showcase/mindfulshopper/images/sc2.png",
      },
      {
        caption: "Five different calculators for a variety of situations",
        screen: "/images/mobile/Showcase/mindfulshopper/images/sc4.png",
      },
      {
        caption: "Simple and intuitive design",
        screen: "/images/mobile/Showcase/mindfulshopper/images/sc5.png",
      },
    ],
  },
  askel: {
    title: "Askel",
    headerMain: "Askel",
    headerSecondary: "an iOS app",
    badgeId: "1569601222",
    storeLink: "https://apps.apple.com/us/app/askel/id1569601222",
    heading: "Askel",
    subtitle: "Running Log",
    screens: [
      {
        caption: "See a summary of your running",
        screen: "/images/mobile/Showcase/askel/images/screen1.png",
      },
      {
        caption: "Visualize your past runs",
        screen: "/images/mobile/Showcase/askel/images/screen2.png",
      },
      {
        caption: "See an overview of your training",
        screen: "/images/mobile/Showcase/askel/images/screen3.png",
      },
      {
        caption: "Change units, charts and more",
        screen: "/images/mobile/Showcase/askel/images/screen4.png",
      },
    ],
  },
};
