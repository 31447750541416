import React, { useState } from "react";
import Axios from "axios";
import {
  Form,
  Button,
  Message,
  Grid,
  Container,
  Image,
} from "semantic-ui-react";
import "./ContactForm.css";

/*
"https://ubhsuaw1zi.execute-api.eu-north-1.amazonaws.com/dev/send-email",
"https://q6sncdcqpb.execute-api.eu-north-1.amazonaws.com/production/send-email",
*/

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const send = (event) => {
    event.preventDefault();

    if (!name) {
      setErrorMessage("Name is missing");
      return;
    }

    if (!content) {
      setErrorMessage("Missing email content");
      return;
    }

    setErrorMessage("");

    const renderText = `<div>
        <b>Name:</b>
        ${name}
        <br /><br />
        <b>Email:</b>
        ${email ? email : "N/A"}
        <br /><br />
        <b>Content:</b>
        ${content}
      </div>`;

    Axios.post(
      "https://q6sncdcqpb.execute-api.eu-north-1.amazonaws.com/production/send-email",
      {
        text: renderText,
      }
    )
      .then(() => {
        setName("");
        setEmail("");
        setContent("");
        setSuccessMessage("Your email was sent!");
      })
      .catch((error) => {
        console.log("Error sending email", error);
        setSuccessMessage("");
        setErrorMessage(error.message || "Unable to send the email");
      });
  };

  const renderMessage = () => {
    if (errorMessage) {
      return <Message error header="Couldn't Submit" content={errorMessage} />;
    } else if (successMessage) {
      return (
        <Message success header="Form Completed" content={successMessage} />
      );
    }
  };

  const formClass = () => {
    if (errorMessage) {
      return "error";
    } else if (successMessage) {
      return "success";
    }
    return "";
  };

  return (
    <div className="formContainer">
      <Container>
        <h2>Contact me</h2>
        <p>Something on your mind? Feel free to contact me.</p>
        <Grid container>
          <Grid.Column mobile={16} tablet={10} computer={8}>
            <Form inverted className={formClass()}>
              <Form.Field>
                <label>Name*</label>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label>Message*</label>
                <textarea
                  placeholder="Your Message"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </Form.Field>
              <p>* Required field</p>
              {renderMessage()}
              <Button type="submit" onClick={send}>
                Send
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={8}>
            <Image
              src="/images/portrait.jpg"
              alt="Filipp Zimin"
              style={{ maxWidth: "300px", margin: "0 auto" }}
            />
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactForm;
