import React, { useEffect } from "react";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { Container, Divider, Grid, Image } from "semantic-ui-react";
import "./MobileApps.css";

const featuredMobileApps = [
  {
    title: "Play Again",
    src: "/images/mobile/pattern-ninja-screen3.png",
    device: "iPhone7",
    deviceColor: "white",
  },
];

const mobileApps = [
  {
    title: "Askel",
    src: "/images/mobile/askel.png",
    path: "/mobile/askel",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "Pace & Splits",
    src: "/images/mobile/pacesplits_x.png",
    path: "/mobile/pacesplits",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "Plants & Beans",
    src: "/images/mobile/plantsbeans_x.png",
    path: "/mobile/plantsbeans",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "Percentical",
    src: "/images/mobile/percentical_x.png",
    path: "/mobile/percentical",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "Mindful Shopper",
    src: "/images/mobile/mindfulshopper_x.png",
    path: "/mobile/mindfulshopper",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "Vegan Being",
    src: "/images/mobile/veganbeing_x.png",
    path: "/mobile/veganbeing",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "Runable",
    src: "/images/mobile/runable.jpg",
    path: "/mobile/runable",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "Clear Thinker",
    src: "/images/mobile/clearthinker.jpg",
    path: "/mobile/clearthinker",
    device: "iPhoneX",
    deviceColor: "black",
  },
  {
    title: "Top Everything",
    src: "/images/mobile/topeverything.png",
    path: "/mobile/topeverything",
    device: "iPhone7",
  },
];

const oldMobileApps = [
  {
    title: "Great Weather",
    src: "/images/mobile/greatweather.png",
    device: "iPhone7",
  },
  {
    title: "Guitar Updates",
    src: "/images/mobile/guitarupdates.png",
    device: "iPhone7",
  },
  {
    title: "The Real Block",
    src: "/images/mobile/realblock.png",
    device: "iPhone7",
  },
  {
    title: "Super Match Quest",
    src: "/images/mobile/supermatchquest.png",
    device: "iPhone7",
  },
  {
    title: "Taxtastic",
    src: "/images/mobile/taxtastic.png",
    device: "iPhone7",
  },
];

const MobileApps = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const featuredApps = featuredMobileApps.map((app) => {
    const screenProp = <Image src={app.src} />;
    return (
      <Grid.Column key={app.id} mobile={16} tablet={12} computer={8}>
        <h3 style={{ textAlign: "center" }}>{app.title}</h3>
        <Link to={app.path}>
          <div className="device-wrapper" style={{ maxWidth: "100%" }}>
            <div
              className="device"
              data-device={app.device}
              data-orientation="landscape"
              data-color={app.deviceColor ? app.deviceColor : "white"}
            >
              <div className="screen">{screenProp}</div>
            </div>
          </div>
        </Link>
      </Grid.Column>
    );
  });

  const apps = mobileApps.map((app) => {
    const screenProp = <Image src={app.src} />;
    return (
      <Grid.Column key={app.title}>
        <h3 style={{ textAlign: "center" }}>{app.title}</h3>
        <Link to={app.path}>
          <div className="device-wrapper">
            <div
              className="device"
              data-device={app.device}
              data-orientation="portrait"
              data-color={app.deviceColor ? app.deviceColor : "white"}
            >
              <div className="screen">{screenProp}</div>
            </div>
          </div>
        </Link>
      </Grid.Column>
    );
  });

  const oldApps = oldMobileApps.map((app) => {
    const screenProp = <Image src={app.src} />;
    return (
      <Grid.Column key={app.id}>
        <h3 style={{ textAlign: "center" }}>{app.title}</h3>
        <div className="device-wrapper">
          <div
            className="device"
            data-device={app.device}
            data-orientation="portrait"
            data-color={app.deviceColor ? app.deviceColor : "white"}
          >
            <div className="screen">{screenProp}</div>
          </div>
        </div>
      </Grid.Column>
    );
  });

  return (
    <div>
      <Navigation page="Mobile Apps" />
      <header>
        <Container>
          <h1>Mobile apps</h1>
          <p>
            Here are some of my personal mobile apps that I have released over
            the years. Some older apps no longer in the App Store are shown here
            as well.
          </p>
        </Container>
      </header>
      <Divider hidden />
      <Container>
        <Grid stackable centered columns={3}>
          {apps}
        </Grid>
        <Divider hidden />
        <Grid centered columns={1}>
          {featuredApps}
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider horizontal>Deprecated Apps</Divider>
        <Divider hidden />
        <Divider hidden />
        <Grid stackable centered columns={3}>
          {oldApps}
        </Grid>
      </Container>
      <Divider hidden />
    </div>
  );
};

export default MobileApps;
