import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Icon, Grid, Divider } from "semantic-ui-react";

const Main = () => {
  return (
    <div>
      <header>
        <Container>
          <h1>Filipp Zimin</h1>
          <h2>Senior Software Developer</h2>
          <p>
            Hello! My name is Filipp and I'm a software developer with over 12
            years of professional experience. I have spent around 4 years of my
            career as a mobile developer, and the rest as a Fullstack developer.
            I enjoy working with modern web and cloud technologies, especially
            React, as well as doing mobile development.
          </p>
          <p>I am a SAFe certified Scrum Master and Product Owner/Manager.</p>
          <Button
            color="linkedin"
            href="https://www.linkedin.com/in/filipp-zimin/"
          >
            <Icon name="linkedin" /> LinkedIn
          </Button>
        </Container>
      </header>
      <Divider hidden />
      <Container>
        <h2>Skills and Experiences</h2>
        <p>
          Below is a list of some of my key skills and experiences. It would
          take too long to list everything I ever worked with, but the lists
          below cover the most important ones. Click to see a sample of some of
          my <Link to="/pro/mobile">professional mobile apps</Link>, and a small
          sample of <Link to="/pro/web">professional web apps</Link>. The link
          to my personal projects is located in a section below.
        </p>
        <Grid stackable columns={3}>
          <Grid.Column>
            <h3>Front-End</h3>
            <ul>
              <li>HTML5 + CSS3</li>
              <li>JavaScript</li>
              <li>React</li>
              <li>Redux</li>
              <li>TypeScript</li>
              <li>jQuery</li>
            </ul>
          </Grid.Column>
          <Grid.Column>
            <h3>Backend</h3>
            <ul>
              <li>AWS</li>
              <li>Node.js + Express</li>
              <li>Serverless</li>
              <li>MySQL, NoSQL, MongoDB</li>
              <li>Firebase</li>
              <li>PHP</li>
            </ul>
          </Grid.Column>
          <Grid.Column>
            <h3>Mobile</h3>
            <ul>
              <li>Swift</li>
              <li>RxSwift</li>
              <li>React Native</li>
            </ul>
          </Grid.Column>
        </Grid>
        <Divider section />
        <h2>Hobbies</h2>
        <p>
          In my spare time, I primarily like working on personal coding
          projects, such as <Link to="/mobile">mobile apps</Link>, studying and{" "}
          <Link to="/running">running</Link>. I also like reading, playing
          electric guitar and some times videogames.
        </p>
        <Divider section />
        <h2>About the Site</h2>
        <p>
          This site is build using React and is powered by AWS: Route 53,
          CloudFront, S3, Lambda and SES (for sending emails).
        </p>
      </Container>
      <Divider hidden />
    </div>
  );
};

export default Main;
