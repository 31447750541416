import React from "react";
import { Grid, Image } from "semantic-ui-react";

const RunningCard = (props) => {
  const { title, description, src, columnClass } = props.image;

  const imageClass = `image-card ${columnClass ? columnClass : ""}`;

  return (
    <div className={imageClass} onClick={() => props.handleOpen(title, src)}>
      <h3>{title}</h3>
      <p>{description}</p>
      <Image src={src} alt={title} wrapped ui={true} />
    </div>
  );
};

export default RunningCard;
