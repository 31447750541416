import React from "react";
import { Container, Divider } from "semantic-ui-react";

const Privacy = () => {
  return (
    <div>
      <header>
        <Container>
          <h1>Privacy Policy</h1>
        </Container>
      </header>
      <Divider hidden />
      <Container>
        <p>
          I, Filipp Zimin, the developer if this app, respect your privacy very
          highly and do not collect any personal information on your use of the
          app.
          <br />
          No third-party services are used to collect or store personal
          information.
          <br />
          Internet connection is required to make possible in-app purchases
          using Apple's secure payment methods.
        </p>
      </Container>
      <Divider hidden />
    </div>
  );
};

export default Privacy;
