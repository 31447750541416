import React, { useState, useEffect } from "react";
import { Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { apps } from "./Showcase/apps";
import Helmet from "react-helmet";
import "./MobileApp.css";

const MobileApp = (props) => {
  const [screenNumber, setScreenNumber] = useState(0);

  const year = new Date().getFullYear();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const name = props.match.params.name;

  const app = apps[name];

  if (!app) {
    return (
      <Container>
        <h1>Oops, no such app...</h1>
      </Container>
    );
  }

  const nextScreen = () => {
    if (screenNumber + 1 < app.screens.length) {
      setScreenNumber(screenNumber + 1);
    } else {
      setScreenNumber(0);
    }
  };

  const previousScreen = () => {
    if (screenNumber - 1 >= 0) {
      setScreenNumber(screenNumber - 1);
    } else {
      setScreenNumber(app.screens.length - 1);
    }
  };
  const badge = `app-id=${app.badgeId}`;
  return (
    <div>
      <Helmet>
        <title>{app.title}</title>
        <meta name="author" content="Filipp Zimin" />
        <meta name="apple-itunes-app" content={badge} />
      </Helmet>
      <div className="showcase-container">
        <div className="showcase-top clearfix">
          <Link to="/mobile">
            <strong>&laquo; All apps</strong>
          </Link>
          <span className="right">
            <Link to="/">app by Filipp Zimin</Link>
          </span>
        </div>
        <header className="clearfix">
          <div className="inner">
            <h1 style={{ color: "#000" }}>
              {app.headerMain}
              <span>{app.headerSecondary}</span>
            </h1>
            <nav className="header-links">
              <a href={app.storeLink} target="_blank" rel="noreferrer">
                <img
                  className="centered"
                  src="/images/mobile/Showcase/inc/apple-badge.svg"
                  alt="Download on the Apple App Store"
                />
              </a>
            </nav>
          </div>
        </header>
        <div className="main">
          <div className="ac-wrapper">
            <h2>
              {app.heading}
              <span>{app.subtitle}</span>
            </h2>
            <div className="ac-device">
              <div>
                <img
                  src={app.screens[screenNumber].screen}
                  alt={app.screens[screenNumber].caption}
                />
              </div>
              <h3 className="ac-title">{app.screens[screenNumber].caption}</h3>
              <nav>
                <span onClick={previousScreen}>&lt;</span>
                <span onClick={nextScreen}>&gt;</span>
              </nav>
            </div>
          </div>
          <p style={{ color: "#000" }}>
            &copy; Copyright {year} Filipp Zimin. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
